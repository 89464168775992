/**
 * Created by Alexandre on 2/3/2016.
 */

//RAF Polyfill
(function() {
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function() {
                    callback(currTime + timeToCall);
                },
                timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };
}());
//End RAF Polyfill

//declare global variable
window.scrollPos = {};

function getScroll(elem, iscroll) {
    var x, y;

    if (Modernizr.touch && iscroll) {
        x = iscroll.x * -1;
        y = iscroll.y * -1;
    } else {
        x = elem.scrollLeft;
        y = elem.scrollTop;
    }

    return {
        x: x,
        y: y
    };
}

var mobile = 767,
    tablet = 991,
    desktop = 1280,
    custom = 1049;

//Using intention.js to move dom element across the page
var responsive = intent.responsive({
    ID: "responsive",
    contexts: [{
        name: "desktop",
        min: tablet,
        max: window.screen.availWidth + 5
    }, {
        name: "mobile",
        min: 0,
        max: mobile
    }, {
        name: "tablet",
        min: mobile,
        max: tablet
    }],
    matcher: function(test, context) {
        return (test > context.min && test <= context.max);
    },
    measure: function(arg) {
        return window.innerWidth;
    }
});

$(window).on("resize", function() {
    responsive.respond();
    quotesInBlockQuotes();
});

$(document).ready(function() {
    responsive.respond();
    quotesInBlockQuotes();

    setTimeout(function (){
        quotesInBlockQuotes();
    });

    $(".scroll-to-top-btn").on("click", function (){
        $("html, body").stop(true, false).animate({
            scrollTop: 0
        },500);
    });
});


//for chapter 3
function quotesInBlockQuotes(){
    if(window.innerWidth <= tablet + 1) {
        setTimeout(function (){
            $("#societe #section5 blockquote span.lquote").height($("#societe #section5 blockquote").height());
        }, 100);
    }else{
        $("#societe #section5 blockquote span.lquote").height(22);
    }
}
